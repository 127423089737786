#__bs_notify__ {
  display: none !important;
}
[class*=tell] {
  display: table-cell;
  vertical-align: middle;
}
[class*=dt] {
  display: table;
  width: 100%;
}
[class*=fw] {
  float: left;
  width: 100%;
}
[class*=item] ul {
  display: table;
  width: 100%;
}
[class*=item] ul li {
  width: 100%;
}
[class*=item] ul li:last-child {
  border-bottom: none;
}
.toutu img {
  width: 100%;
  vertical-align: bottom;
}
.mobile-wrap {
  position: relative;
  min-height: 100vh;
}
main {
  width: 100%;
  padding: 20px 5%;
}
.appItem {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 40px;
}
.appItem .left {
  width: 230px;
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
}
.appItem .left img {
  width: 100%;
  min-height: 100%;
}
.appItem .right {
  width: calc(100% - 260px);
}
.appItem .right strong {
  color: #111;
  font-size: 40px;
  line-height: 1.4;
  display: block;
}
.appItem .right strong span {
  color: #8e8e93;
  font-size: 24px;
  border-radius: 8px;
  padding: 2px 10px;
  border: 1px solid #8e8e93;
  vertical-align: middle;
  margin-left: 10px;
}
.appItem .right p {
  font-size: 28px;
  line-height: 1.4;
  color: #8e8e93;
}
.appItem .right .installBox {
  padding-top: 40px;
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.appItem .right .installBox a {
  display: inline-block;
}
.appItem .right .installBox a:active {
  opacity: 0.85;
}
.appItem .right .installBox .down {
  min-width: 210px;
  background-color: #017afe;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  padding: 0 15px;
  height: 62px;
  line-height: 62px;
}
.appItem .right .installBox .doubt {
  width: 62px;
  height: 62px;
  line-height: 62px;
  border-radius: 50%;
  background-color: #017afe;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
.appItem .appTip {
  width: 100%;
  padding-top: 40px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.appItem .appTip .score {
  line-height: 1.2;
}
.appItem .appTip .score .star {
  color: #8e8e93;
  font-weight: bold;
  font-size: 34px;
}
.appItem .appTip .score .star var {
  width: 160px;
  height: 32px;
  background: url("../img/star.svg") 0 0;
  background-size: 0.32rem 0.72rem;
  display: inline-block;
}
.appItem .appTip .score p {
  color: #d8d8d8;
  font-size: 24px;
  line-height: 1.6;
}
.appItem .appTip .centerBox {
  color: #8e8e93;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}
.appItem .appTip .centerBox i {
  position: relative;
  top: -10px;
}
.appItem .appTip .age {
  color: #8e8e93;
  line-height: 1.2;
  text-align: right;
}
.appItem .appTip .age b {
  font-size: 34px;
  display: block;
}
.appItem .appTip .age p {
  color: #d8d8d8;
  font-size: 24px;
}
.comment {
  width: 100%;
  padding: 34px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.comment .left {
  text-align: center;
  padding-left: 20px;
}
.comment .left b {
  font-size: 120px;
  color: #4c4c50;
  line-height: 1.1;
}
.comment .left p {
  font-size: 28px;
  color: #8e8e93;
  font-weight: bold;
}
.comment .right {
  flex-grow: 0.9;
}
.comment .right p {
  font-size: 28px;
  color: #8e8e93;
  line-height: 1.4;
  text-align: right;
}
.comment .right .star_row {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment .right .star_row span {
  width: 120px;
  height: 24px;
  display: inline-block;
  text-align: right;
}
.comment .right .star_row span i {
  height: 16px;
  display: inline-block;
  background: url("../img/star.svg") 0 0;
  background-size: 18px 34px;
}
.comment .right .star_row span.s1 i {
  width: 90px;
}
.comment .right .star_row span.s2 i {
  width: 72px;
}
.comment .right .star_row span.s3 i {
  width: 54px;
}
.comment .right .star_row span.s4 i {
  width: 36px;
}
.comment .right .star_row span.s5 i {
  width: 18px;
}
.comment .right .star_row .lineBox {
  width: calc(100% - 160px);
  height: 5px;
  border-radius: 30px;
  background-color: #e5e5e5;
  overflow: hidden;
}
.comment .right .star_row .lineBox var {
  height: 100%;
  border-radius: 0 30px 30px 0;
  background-color: #8e8e93;
  float: left;
}
.comment .right .star_row .lineBox var.v1 {
  width: 90%;
}
.comment .right .star_row .lineBox var.v2 {
  width: 10%;
}
.comment .right .star_row .lineBox var.v3 {
  width: 4%;
}
.comment .right .star_row .lineBox var.v4 {
  width: 2%;
}
.comment .right .star_row .lineBox var.v5 {
  width: 1%;
}
.publicTitle {
  width: 100%;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 2px;
  margin-bottom: 30px;
  display: block;
}
.newFunction {
  width: 100%;
  padding: 34px 0;
  border-bottom: 1px solid #e5e5e5;
  line-height: 1.4;
}
.newFunction p {
  font-size: 30px;
  color: #333;
}
.appInfo {
  width: 100%;
  padding: 34px 0;
}
.appInfo .box ul li {
  width: 100%;
  line-height: 1.4;
  padding: 15px 0;
  float: none;
  border-bottom: 1px solid #e5e5e5;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.appInfo .box ul li:last-child {
  border-bottom: none;
}
.appInfo .box ul li span {
  flex-grow: 0.1;
  font-size: 24px;
  color: #8e8e93;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appInfo .box ul li p {
  width: 80%;
  color: #333;
  font-size: 24px;
  text-align: right;
  display: inline-block;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
}
.footer {
  width: 100%;
  padding: 15px 3%;
  background-color: #eee;
  color: #a9a9a9;
  line-height: 1.6;
}
.footer p {
  font-size: 20px;
}
.footer p.p2 {
  text-indent: 40px;
}
.pup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  display: none;
}
.guide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 20px;
}
.guide .colse {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  background: url("../img/31cb4_4_200_200.png") no-repeat;
  background-size: 100%;
}
.guide .pics {
  width: 100%;
  height: 550px;
}
.guide .pic {
  width: 100%;
  height: 420px;
  overflow: hidden;
}
.guide .pic img {
  width: 100%;
  min-height: 100%;
}
.guide .text {
  padding: 15px;
  color: #1e93ff;
  font-size: 26px;
  line-height: 1.6;
  text-align: center;
}
.guide .swiper-container {
  height: 100%;
}
.guide .swiper-pagination {
  bottom: 0 !important;
}
.guide .swiper-pagination span {
  background-color: #def1ff;
  opacity: 1;
}
.guide .swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #70c2fe;
  opacity: 1;
}
.guide .smallTip {
  text-align: center;
  line-height: 1.4;
  padding-top: 30px;
}
.guide .smallTip a {
  color: #1daafc;
  font-size: 24px;
}
.pupPic {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.pupPic img {
  width: 100%;
  height: 100%;
}
